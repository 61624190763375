<template>
  <canvas class="pixel__canvas-layer"
          v-bind:class="[{active: layer.id === currentLayer.id}, {hidden: !layer.visible}]"
          :id="`canvas-${layer.id}`"
          v-bind:style="[{'width': `${canvasSizeWithZoom.width}px`},
                       {'height': `${canvasSizeWithZoom.height}px`},
                       {'z-index': `${index}`},]"
          ref="canvas"
          @mousemove="canvasMouseMove" @mousedown="canvasMouseDown"
          @mouseup="canvasMouseUp" @mouseout="canvasMouseOut">
  </canvas>
</template>


<script>
import {mapGetters, mapState} from 'vuex';
import {DesignTool, PIXELS_PER_ONE_BRICK} from '@/store/modules/design'

export default {
  name: "Canvas",
  data() {
    return {
      canvas: null,
      ctx: null,
      isInited: false,
      mouseState: null,
    }
  },
  props: ['index'],
  computed: {
    // colors() {
    //   return this.$store.state.admin.colors;
    // }
    // ...mapGetters(['currentLayer','project'])
    layer() {
      return this.project.layers[this.index];
    },
    plateSize() {
      return this.project.meta.plate_size;
    },
    bricksWidth() {
      return this.project.meta.canvas_width * this.plateSize
    },
    bricksHeight() {
      return this.project.meta.canvas_height * this.plateSize
    },
    availableColors() {
      return this.colors.filter(x => x.palettes.includes(this.project.meta.palette));
    },
    ...mapGetters('design', ['currentLayer', 'canvasSizeWithZoom', 'canvasSize']),
    ...mapState('colors', ['colorsImages', 'colors']),
    ...mapState('design', ['tool', 'project', 'zoom', 'currentColor']),
  },
  methods: {
    processMouseEvent(e) {
      e.stopPropagation();
      e.preventDefault();

      let bounds = this.canvas.getBoundingClientRect();
      let x = parseInt(((e.clientX - bounds.left) / PIXELS_PER_ONE_BRICK / this.zoom));
      let y = parseInt(((e.clientY - bounds.top) / PIXELS_PER_ONE_BRICK / this.zoom));
      switch (this.tool) {
        case DesignTool.Brush:
          let img = this.colorsImages[this.currentColor.id];
          this.ctx.drawImage(img, x * PIXELS_PER_ONE_BRICK, y * PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK);
          this.setColor(x, y, this.currentColor.id);
          return true;
        case DesignTool.Fill:
          let oldColorId = this.getColor(x, y);
          let newColorId = this.currentColor.id;
          this.floodFill(x, y, oldColorId, newColorId);
          break;
        case DesignTool.Replace:
          let oldColorToReplaceId = this.getColor(x, y);
          let newColorToReplaceId = this.currentColor.id;
          let imgToReplace = this.colorsImages[newColorToReplaceId];
          for (let i = 0; i < this.layer.data.length; i++) {
            x = i % this.bricksWidth;
            y = parseInt(i / this.bricksWidth);
            if (this.layer.data[i] === oldColorToReplaceId) {
              this.setColor(x, y, newColorToReplaceId);
              this.ctx.drawImage(imgToReplace, x * PIXELS_PER_ONE_BRICK, y * PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK);
            }
          }
          break;
        case DesignTool.Erase:
          let emptyImage = this.ctx.createImageData(PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK);
          this.setColor(x, y, 0)
          this.ctx.putImageData(emptyImage, x * PIXELS_PER_ONE_BRICK, y * PIXELS_PER_ONE_BRICK);
          return true;
      }
      return false;
    },
    getColor(x, y) {
      return this.layer.data[y * this.bricksWidth + x];
    },
    setColor(x, y, colorId) {
      this.layer.data[y * this.bricksWidth + x] = colorId;
    },
    floodFill(x, y, oldColorId, newColorId) {
      if (x >= 0 && x < this.bricksWidth && y >= 0 && y < this.bricksHeight) {
        if (this.getColor(x, y) === oldColorId && this.getColor(x, y) !== newColorId) {
          this.setColor(x, y, newColorId);
          let img = this.colorsImages[newColorId];
          this.ctx.drawImage(img, x * PIXELS_PER_ONE_BRICK, y * PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK);
          this.floodFill(x + 1, y, oldColorId, newColorId);
          this.floodFill(x - 1, y, oldColorId, newColorId);
          this.floodFill(x, y + 1, oldColorId, newColorId);
          this.floodFill(x, y - 1, oldColorId, newColorId);
        }
      }
    },
    canvasMouseOut(e) {
      this.mouseState = null;
    },
    canvasMouseDown(e) {
      this.$store.commit('design/historyAdd')

      let shouldMouseSetDown = this.processMouseEvent(e);
      if (shouldMouseSetDown) {
        this.mouseState = 'action';
      } else {
        this.mouseState = null;
      }
    },
    canvasMouseUp(e) {
      this.mouseState = null;
    },
    canvasMouseMove(e) {
      if (this.mouseState === 'action') {
        this.processMouseEvent(e);
      }
    },
    redraw() {
      let ctx = this.ctx;
      let canvas = this.canvas;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let x = 0;
      let y = 0;
      let id = 0
      let plateSize = this.project.meta.plate_size;
      let bricksWidth = this.project.meta.canvas_width * plateSize;
      let bricksHeight = this.project.meta.canvas_height * plateSize;
      for (let i = 0; i < this.layer.data.length; i++) {
        x = i % bricksWidth;
        y = parseInt(i / bricksWidth);

        id = this.layer.data[i];
        if (id > 0) {
          let img = this.colorsImages[this.layer.data[i]];
          // console.log(typeof img);
          ctx.drawImage(img, x * PIXELS_PER_ONE_BRICK, y * PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BRICK);
        } else {
          // let img = this.colorsImages[1];
          // ctx.drawImage(img, x * PIXELS_PER_ONE_BRICK, y * PIXELS_PER_ONE_BRICK, PIXELS_PER_ONE_BR ICK, PIXELS_PER_ONE_BRICK);
        }
      }
    },
  },
  mounted() {
    if (this.project.id == 0) {
      // console.log('Project not loaded')
      return
    }

    this.$store.subscribe((mutation, state) => {
      // console.log(mutation.type)
      if (mutation.type === 'design/historyUndo' || mutation.type === 'design/historyRedo') {
        this.redraw();
      }
      // Изменился файл
      if (mutation.type === 'design/editFile') {
        // Изменился текущий слой
        if (this.layer.file_id == mutation.payload.file_id) {
          // Изменился размер слоа
          let canvas = this.canvas;
          canvas.width = this.canvasSize.width;
          canvas.height = this.canvasSize.height;
          // Изменились данные слоя
          this.redraw();
        }
      }
    });

    var canvas = this.$refs['canvas'];
    canvas.width = this.canvasSize.width;
    canvas.height = this.canvasSize.height;
    let ctx = canvas.getContext('2d');

    ctx.imageSmoothingEnabled = false;


    this.canvas = canvas;
    this.ctx = ctx;

    this.redraw();

    this.isInited = true;
  },

}
</script>


<style lang="scss" scoped>
.pixel {
  &__canvas-layer {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
      pointer-events: auto;
    }

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>
